<template>
    <div>
        <section class="signup">
            <h2 class="signup__title">{{ $t("register.title") }}</h2>
            <div class="signup__inner">
                <div class="signup-left">
                    <h3 class="signup-left__title">{{ $t("register.yourUpLine") }}</h3>
                    <div class="signup-left__input">
                        <span>
                            <UiAvatar :user="upline" />
                        </span>
                        <input type="text" :placeholder="upline.name" readonly />
                    </div>
                    <div class="signup-left__confirm">
                        <UiCheckbox :value="agreeTerms" :error="agreeError" @onChange="(v) => (agreeTerms = v)">
                            {{ $t("register.agree") }}&nbsp;
                            <a href="#" @click.prevent="setTermsModal({ visible: true })">
                                {{ $t("register.agreeLink") }}
                            </a>
                        </UiCheckbox>
                    </div>
                    <div class="signup-left__confirm">
                        <UiCheckbox
                            :value="agreeTermsUSA"
                            :error="agreeErrorUSA"
                            @onChange="(v) => (agreeTermsUSA = v)"
                        >
                            {{ $t("register.agreeUSA") }}&nbsp;
                        </UiCheckbox>
                    </div>
                    <button
                        class="signup-left__button desktop glob-btn btn-reset"
                        :class="!agreeTerms || !agreeTermsUSA ? 'disabled' : ''"
                        type="button"
                        :disabled="!agreeTermsUSA || !agreeTerms"
                        @click="handleSignupClick"
                    >
                        <template v-if="someErrors">{{ $t("register.tryAgain") }}</template>
                        <template v-else>{{ $t("register.register") }}</template>
                    </button>
                </div>
                <div class="signup-right">
                    <ul class="signup-line list-reset">
                        <li
                            class="signup-line__item"
                            :class="[process[0].status === false && 'reject', process[0].status === true && 'resolve']"
                        >
                            <span class="signup-line__status">
                                <svg-vue icon="svg-reject" class="signup-line__svg-reject" />
                                <svg-vue icon="svg-resolve" class="signup-line__svg-resolve" />
                            </span>
                            <span class="signup-line__item-desc"
                                >{{ $t("register.wallet") }}{{ process[0].reason }}</span
                            >
                        </li>
                        <li
                            class="signup-line__item"
                            :class="[process[1].status === false && 'reject', process[1].status === true && 'resolve']"
                        >
                            <span class="signup-line__status">
                                <svg-vue icon="svg-reject" class="signup-line__svg-reject" />
                                <svg-vue icon="svg-resolve" class="signup-line__svg-resolve" />
                            </span>
                            <span class="signup-line__item-desc"
                                >{{ $t("register.network") }}{{ process[1].reason }}</span
                            >
                        </li>
                        <li
                            class="signup-line__item"
                            :class="[process[2].status === false && 'reject', process[2].status === true && 'resolve']"
                        >
                            <span class="signup-line__status">
                                <svg-vue icon="svg-reject" class="signup-line__svg-reject" />
                                <svg-vue icon="svg-resolve" class="signup-line__svg-resolve" />
                            </span>
                            <span class="signup-line__item-desc"
                                >{{ $t("register.registerV2") }}{{ process[2].reason }}</span
                            >
                        </li>
                        <li
                            class="signup-line__item"
                            :class="[process[3].status === false && 'reject', process[3].status === true && 'resolve']"
                        >
                            <span class="signup-line__status">
                                <svg-vue icon="svg-reject" class="signup-line__svg-reject" />
                                <svg-vue icon="svg-resolve" class="signup-line__svg-resolve" />
                            </span>
                            <span class="signup-line__item-desc">{{ process[3].reason }}</span>
                            <!-- <span class="signup-line__item-desc">
                                {{ $t("register.balanceOfFunds") }}: {{ process[3].reason }}
                            </span> -->
                        </li>
                    </ul>
                    <div class="signup-left__confirm mobile">
                        <UiCheckbox :value="agreeTerms" :error="agreeError" @onChange="(v) => (agreeTerms = v)">
                            {{ $t("register.agree") }}&nbsp;
                            <a href="#" @click.prevent="setTermsModal({ visible: true })">
                                {{ $t("register.agreeLink") }}
                            </a>
                        </UiCheckbox>
                    </div>

                    <button
                        class="signup-left__button mobile glob-btn btn-reset"
                        type="button"
                        @click="handleSignupClick"
                    >
                        <template v-if="someErrors">{{ $t("register.tryAgain") }}</template>
                        <template v-else>{{ $t("register.register") }}</template>
                    </button>
                </div>
            </div>
        </section>

        <section class="signup-information" v-if="someErrors">
            <div class="signup-information__inner">
                <div class="signup-information-left">
                    <h2 class="signup-information__title section-title">
                        {{ $t("register.information.title") }}
                    </h2>
                    <h3 class="signup-information__subtitle">
                        <span class="signup-information__subtitle-label">!</span>
                        <span>{{ $t("register.information.subTitle") }}</span>
                    </h3>
                    <p class="signup-information__desc">
                        {{ $t("register.information.firstDescription") }}
                    </p>
                    <p class="signup-information__desc">
                        {{ $t("register.information.firstItem") }}
                        <strong>{{ $t("register.information.secondItem") }}</strong>
                        {{ $t("register.information.thirdItem") }}
                        <strong>{{ $t("register.information.fourthItem") }}</strong>
                    </p>
                </div>
                <div class="signup-information-right">
                    <div class="signup-information-banner">
                        <div class="signup-information-banner__info">
                            <h4 class="signup-information-banner__title">
                                {{ $t("register.informationRight.title") }}
                            </h4>
                            <p class="signup-information-banner__desc">
                                {{ $t("register.informationRight.description") }}
                            </p>
                        </div>
                        <div class="signup-information-banner__image">
                            <img src="/assets/phone.png" alt="phone" />
                        </div>
                    </div>
                    <router-link :to="{ name: 'academy' }" class="nav-list__link">
                        <button class="signup-information__button glob-btn btn-reset" type="button">
                            {{ $t("register.informationRight.button") }}
                        </button>
                    </router-link>
                </div>
            </div>
        </section>

        <div class="page-loader" :class="[showPageLoader && 'is-active']">
            <UiLoader :loading="true" />
            <div class="page-loader__message">{{ $t("pleaseWait") }}, <br />{{ $t("operationInProgress") }}</div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import { Log } from "@/helpers/dev"
import { vm } from "@/main.js"
import { getConnectTypeFromSelectWallet } from "@/helpers/params"

const defaultProcess = [
    { status: null, reason: "" },
    { status: null, reason: "" },
    { status: null, reason: "" },
    { status: null, reason: `${vm.$t("register.positiveBalance")} MATIC` }
]

export default {
    props: {
        upline: Object
    },
    data: () => ({
        loading: false,
        showPageLoader: false,
        process: defaultProcess,
        agreeTerms: false,
        agreeTermsUSA: false,
        agreeError: false,
        agreeErrorUSA: false
    }),
    computed: {
        someErrors() {
            return this.process.some((x) => x.status === false)
        },
        ...mapGetters("crypto", ["getMinTransactionFee", "getConnectionWallet"])
    },
    methods: {
        handleSignupClick() {
            if (this.loading) return

            this.agreeError = false

            if (!this.agreeTerms) {
                this.agreeError = true
                return
            }

            this.setConnectionModal({ visible: true, type: "select" })
        },
        async handleSignupBySteps() {
            this.loading = true

            this.process = [...defaultProcess]

            const updateProgress = (i, obj) => {
                this.process = [...this.process.map((x, idx) => (idx === i ? { ...obj } : x))]
            }

            const [connectErr, account] = await this.checkConnect({
                onEthWindow: ([err, res]) => {
                    updateProgress(0, {
                        status: !err,
                        reason: res ? "" : `: ${this.$t("register.steps.notConnected")}`
                    })
                },
                onNetwork: ([err, res]) => {
                    updateProgress(1, {
                        status: !err,
                        reason: res ? "" : `: ${this.$t("register.steps.error")}`
                    })
                }
            })

            if (connectErr) {
                this.loading = false
                return
            }

            const [regErr, regParent] = await this.checkRegistration()
            updateProgress(2, {
                status: regErr !== null,
                reason: regParent ? `: ${this.$t("register.steps.alreadyRegistered")}` : ""
            })

            if (!regErr) {
                if (regParent !== null) {
                    this.$swal(this.$t("register.alreadyRegistered"))
                    await this.$router.push({ name: "dashboard" })
                } else {
                    this.setConnect(false)
                }

                this.loading = false
                return
            }

            const [balanceErr, balanceBnb] = await this.getBalances(account)
            await this.getGas()

            Log({ balanceBnb }, { minTransaction: this.getMinTransactionFee })
            const enoughBalance = !balanceErr && Number(balanceBnb) >= this.getMinTransactionFee

            updateProgress(3, {
                status: enoughBalance,
                reason: enoughBalance
                    ? `${this.$t("register.positiveBalance")} MATIC`
                    : `${this.$t("register.negativeBalance")} MATIC`
            })

            if (!enoughBalance) {
                this.loading = false
                return
            }

            // register
            const [registrationErr, registrationRes] = await this.registerNewAccount({
                account: account,
                parentAcc: this.upline.account,
                onBlockchainPending: () => {
                    this.showPageLoader = true
                }
            })
            this.showPageLoader = false
            this.loading = false

            if (registrationRes) {
                this.$swal(this.$t("register.success"))
                await this.$router.push({ name: "dashboard" })
            } else {
                this.$swal(registrationErr.message)
            }
        },
        ...mapMutations("crypto", ["setConnect"]),
        ...mapMutations("ui", ["setConnectionModal", "setTermsModal"]),
        ...mapActions("crypto", ["checkConnect", "checkRegistration", "getBalances", "getGas", "registerNewAccount"])
    },
    watch: {
        getConnectionWallet(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                const type = getConnectTypeFromSelectWallet(newVal)
                if (type === "default") {
                    this.handleSignupBySteps()
                }
            }
        }
    }
}
</script>
