<template>
    <div class="alighting-card-wrong" :class="[className, visible && 'show', error && 'error']">
        <p class="alighting-card-wrong__desc">{{ $t("register.error") }}</p>
        <div class="alighting-card-wrong__fields">
            <div class="alighting-card-wrong__wrapper">
                <div class="alighting-card-wrong__input">
                    <span>
                        <template v-if="!uplineData">?</template>
                        <UiAvatar v-else-if="uplineData" :user="uplineData" />
                    </span>
                    <input type="text" placeholder="ForceID" v-model="uplineInput" />
                    <div class="alighting-card-wrong__input-confirm" v-if="uplineData">
                        <svg-vue icon="svg-resolve" />
                    </div>
                </div>
                <button class="alighting-card-wrong__accept glob-btn btn-reset" type="button" @click="checkUpline">
                    {{ $t("accept") }}
                </button>
            </div>

            <p class="registry-input-error mobile">{{ $t("register.error") }}</p>
        </div>
        <p class="registry-input-error desktop">{{ $t("register.error") }}</p>
    </div>
</template>

<script>
import { mapActions } from "vuex"
import { isForceId, isHashAddress } from "@/helpers/user"

export default {
    props: {
        className: String,
        visible: Boolean,
        error: Boolean,
        uplineData: Object,
    },
    data: () => ({
        uplineInput: "",
    }),

    methods: {
        async checkUpline() {
            const id = this.uplineInput
            this.$emit("onError", false)
            this.$emit("onData", null)

            let requestParams = null
            if (isHashAddress(id)) {
                requestParams = { account: id }
            } else if (isForceId(id)) {
                requestParams = { name: id }
            }

            if (!requestParams) {
                this.$emit("onError", true)
                return
            } else {
                this.$emit("onLoading", true)
            }

            await this.getUserByField(requestParams)
                .then(({ users }) => {
                    this.$emit("onData", users)
                })
                .catch((err) => {
                    this.$emit("onError", true)
                })

            this.$emit("onLoading", false)
        },
        ...mapActions("user", ["getUserByField"]),
    },
}
</script>

<style lang="scss" scoped>
.alighting-card-wrong {
    margin-top: 15px;
    display: none;
    &.show {
        display: block;
    }
    &__desc {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 10px;
    }
    &__fields {
        display: flex;
        position: relative;
        @media screen and (max-width: 576px) {
            flex-direction: column;
            align-items: center;
        }
    }
    &__wrapper {
        width: 100%;
        display: flex;
        @media screen and (max-width: 576px) {
            display: block;
        }
    }
    &__input {
        flex: 1 1 auto;
        position: relative;
        width: 100%;
        margin-right: -20px;
        @media screen and (max-width: 576px) {
            margin-right: 0;
        }
        span {
            position: absolute;
            top: 10px;
            left: 15px;
            background: #a2a2a6;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            width: 31px;
            height: 31px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        input {
            outline: none;
            border: 1px solid transparent;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: var(--color-silver);
            margin: 0;
            width: 100%;
            padding: 15px 56px 15px 56px;
            background-color: var(--color-cinder);
            border-radius: 20px 0px 0px 20px;
            @media screen and (max-width: 576px) {
                border-radius: 20px;
                padding-right: 36px;
            }
        }
    }
    &__input-confirm {
        position: absolute;
        top: 50%;
        right: 36px;
        transform: translateY(-50%);
        font-size: 8px;
        @media screen and (max-width: 576px) {
            right: 16px;
        }
    }
    &__accept {
        position: relative;
        z-index: 2;
        padding-left: 60px;
        padding-right: 60px;
        @media screen and (max-width: 576px) {
            margin-top: 20px;
        }
    }
}
</style>
