<template>
    <div>
        <section class="registry">
            <div class="registry__inner" :class="[selectError && 'error']">
                <h2 class="registry__title">{{ $t("register.title") }}</h2>
                <h3 class="registry__subtitle">{{ $t("register.subTitle") }}</h3>
                <div class="registry-labels">
                    <label class="registry-label-wrapper label-wrapper">
                        {{ $t("yes") }}
                        <input
                            class="label-wrapper__input"
                            name="upline01"
                            type="radio"
                            :checked="hasUpline === true"
                            @change="handleRadioChange(true)"
                        />
                        <span class="label-wrapper__checkmark"></span>
                    </label>
                    <label class="registry-label-wrapper label-wrapper">
                        {{ $t("no") }}
                        <input
                            class="label-wrapper__input"
                            name="upline01"
                            type="radio"
                            :checked="hasUpline === false"
                            @change="handleRadioChange(false)"
                        />
                        <span class="label-wrapper__checkmark"></span>
                    </label>
                </div>

                <p class="registry-choose">{{ $t("register.choose") }}</p>

                <UplineSearch
                    class="registry-card-wrong"
                    :visible="hasUpline === true"
                    :error="uplineError"
                    :uplineData="uplineData"
                    @onError="(v) => (uplineError = v)"
                    @onLoading="(v) => (loading = v)"
                    @onData="(v) => (uplineData = v)"
                />

                <button
                    v-if="hasUpline !== null"
                    class="registry__now glob-btn btn-reset"
                    type="button"
                    @click="handleRegisterClick"
                >
                    <UiLoader v-if="loading" :loading="true" theme="btn" color="#000" />
                    <span v-else>{{ $t("register.registerNow") }}</span>
                </button>
            </div>
        </section>

        <RegisterHelp />
    </div>
</template>

<script>
import { mapActions } from "vuex"
import { DICT } from "@/config/constants"
import UplineSearch from "@/components/Register/UplineSearch.vue"
import RegisterHelp from "@/components/Register/RegisterHelp.vue"
export default {
    components: {
        UplineSearch,
        RegisterHelp
    },
    data: () => ({
        hasUpline: null,
        selectError: false,

        uplineData: null,
        uplineError: false,
        loading: false
    }),

    methods: {
        handleRadioChange(v) {
            this.hasUpline = v
            this.selectError = false
        },
        async handleRegisterClick() {
            if (this.loading) return

            if (this.hasUpline === null) {
                this.selectError = true
            } else if (this.hasUpline === false) {
                this.loading = true
                await this.getUserByField({ account: DICT.CREATOR })
                    .then(({ users }) => {
                        this.$emit("onUplineSelect", users)
                    })
                    .catch((err) => {
                        // to make debug possible without changing api
                        // this.$swal(this.$t("functionalityError"))
                        this.$emit("onUplineSelect", {
                            id: 1,
                            name: "Meta_Force",
                            email: "admin@mail.ru",
                            account: DICT.CREATOR,
                            avatar: "https://metaforce-hub.fra1.digitaloceanspaces.com/images/avatar.jpg",
                            parent: DICT.CREATOR
                        })
                    })

                this.loading = false
            } else if (this.hasUpline === true) {
                if (this.uplineData) {
                    this.$emit("onUplineSelect", this.uplineData)
                } else {
                    this.uplineError = true
                }
            }
        },
        ...mapActions("user", ["getUserByField"])
    }
}
</script>
